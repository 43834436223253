// src/pages/PackPage.js

import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardContent,
} from '@mui/material';
import PuzzleLayout from '../components/PuzzleLayout';
import PuzzleSelectionInterface from '../components/PuzzleSelectionInterface';  // Import the PuzzleSelectionInterface
import { useThemeContext } from '../contexts/ThemeContext';
import { useMediaContext } from '../contexts/MediaContext';
import { useLocation } from 'react-router-dom';
import { PuzzleDataContext } from '../contexts/PuzzleDataContext';
import { getPackGradient } from '../utils/utilityFunctions';
import usePackPuzzles from '../hooks/usePackPuzzles';
import useWindowDimensions from '../hooks/useWindowDimensions';


const PackPage = () => {
  const { currentTheme } = useThemeContext();
  const { isMobile } = useMediaContext();
  const { puzzleData } = useContext(PuzzleDataContext);
  const [selectedPack, setSelectedPack] = useState(null);
  const [selectedPuzzleNo, setSelectedPuzzleNo] = useState(null);
  const location = useLocation();
  
  const { height: windowHeight } = useWindowDimensions();

  // Fetch pack puzzles and stats
  const { packPuzzles, stats } = usePackPuzzles(selectedPack, puzzleData);

  useEffect(() => {
    if (location.state?.reset) {
      setSelectedPack(null);
      setSelectedPuzzleNo(null);
    }
  }, [location.state]);

  const totalPuzzles = 900;
  const puzzlesPerPack = 100;
  const totalPacks = 9;

  // Define packs with their start and end puzzle numbers
  const puzzlePacks = Array.from({ length: totalPacks }, (_, index) => {
    const packNumber = index + 1;
    const start = index * puzzlesPerPack + 1;
    const end = Math.min((index + 1) * puzzlesPerPack, totalPuzzles);
    return {
      packNumber,
      start,
      end,
      gradient: getPackGradient(packNumber),
    };
  });

  return (
    <Box
      sx={{
        color: currentTheme.textColor,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Pack Selection */}
      {!selectedPack && !selectedPuzzleNo && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            width: 'auto',
            height: windowHeight-70, //safe height - heading and padding
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, auto)',
              gridGap: '10px',
              justifyContent: 'center',
            }}
          >
            {puzzlePacks.map((pack) => (
              <Card
                key={pack.packNumber}
                sx={{
                  background: pack.gradient,
                  color: '#fff',
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                  transition: 'transform 0.2s',
                  width: `${isMobile ? 90 : 130}px`,
                  height: `${isMobile ? 90 : 130}px`,
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <CardActionArea
                  onClick={() => {
                    setSelectedPack(pack);
                  }}
                  sx={{ width: '100%', height: '100%' }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <CardContent
                      sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        padding: 0, // Removed default padding
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#fff' }}>
                        Pack {pack.packNumber}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#fff' }}>
                        {pack.start} - {pack.end}
                      </Typography>
                    </CardContent>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Box>
        </Box>
      )}

      {/* Puzzle List */}
      {selectedPack && !selectedPuzzleNo && (
        <PuzzleSelectionInterface
          stats={stats}
          puzzleList={packPuzzles}  // Use packPuzzles as the puzzle list
          setSelectedPuzzleNo={setSelectedPuzzleNo}  // Pass setSelectedPuzzleNo to handle puzzle selection
          puzzleType={'pack'}
          packNumber={selectedPack.packNumber}
          backgroundGradient = {selectedPack.gradient}
        />
      )}

      {/* Puzzle Play */}
      {selectedPuzzleNo && (
        <PuzzleLayout packPuzzleNo={selectedPuzzleNo} setSelectedPuzzleNo={setSelectedPuzzleNo} puzzleType="pack" />
      )}
    </Box>
  );
};

export default PackPage;
