// src/components/PuzzleLayout.js

import React from 'react';
import { Box } from '@mui/material';
import Puzzle from '../components/Puzzle';
import { useThemeContext } from '../contexts/ThemeContext';
import useWindowDimensions from '../hooks/useWindowDimensions';

const PuzzleLayout = ({archivePuzzleNo = null, packPuzzleNo = null, setSelectedPuzzleNo, puzzleType }) => {
  const { currentTheme } = useThemeContext();
  const { height: windowHeight } = useWindowDimensions();

  return (
    <Box
      sx={{
        color: currentTheme.textColor,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Game Area */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: windowHeight - 70,
          overflow: 'hidden',
        }}
      >
        {/* Puzzle Goes Here */}
        <Puzzle
          archivePuzzleNo={archivePuzzleNo}
          packPuzzleNo={packPuzzleNo}
          setSelectedPuzzleNo={setSelectedPuzzleNo}
          puzzleType={puzzleType}
        />
      </Box>
    </Box>
  );
};

export default PuzzleLayout;
