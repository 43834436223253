// src/pages/ArchivePage.js

import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import PuzzleLayout from '../components/PuzzleLayout';
import PuzzleSelectionInterface from '../components/PuzzleSelectionInterface';
import { useThemeContext } from '../contexts/ThemeContext';
import { useLocation } from 'react-router-dom';
import { PuzzleDataContext } from '../contexts/PuzzleDataContext';
import useDailyPuzzleNumber from '../hooks/useDailyPuzzleNumber';
import useArchivePuzzles from '../hooks/useArchivePuzzles';
import Loading from "../components/Loading";

const ArchivePage = () => {
  const { currentTheme } = useThemeContext();
  const { puzzleData } = useContext(PuzzleDataContext);
  const [selectedPuzzleNo, setSelectedPuzzleNo] = useState(null);
  const [showUnplayedOnly] = useState(false);
  const [sortOrder] = useState('newest');
  const [selectedTab, setSelectedTab] = useState(0);
  const location = useLocation();

  // Use custom hooks to get daily puzzle data and process archive puzzles
  const {
    todayPuzzleNo,
    todayPuzzleDate,
    todayDailyDoublePuzzleNo,
    todayDailyDoublePuzzleDate,
    loading,
    error,
  } = useDailyPuzzleNumber(true);

  const puzzleTypeForHook = selectedTab === 0 ? 'daily' : 'daily-double';
  const todayPuzzleNumber =
    puzzleTypeForHook === 'daily' ? todayPuzzleNo : todayDailyDoublePuzzleNo;
  const todayPuzzleDateValue =
    puzzleTypeForHook === 'daily' ? todayPuzzleDate : todayDailyDoublePuzzleDate;

  const { puzzleList, stats } = useArchivePuzzles(
    todayPuzzleNumber,
    todayPuzzleDateValue,
    puzzleData,
    sortOrder,
    showUnplayedOnly,
    puzzleTypeForHook
  );

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedPuzzleNo(null); // Reset selected puzzle when switching tabs
  };

  useEffect(() => {
    if (location.state?.reset) {
      setSelectedPuzzleNo(null);
    }
  }, [location.state]);

  if (loading) {
    return (
      <Loading message={" Archive"} />
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
<Box
  sx={{
    color: currentTheme.textColor,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }}
>
  {/* Tabs */}
  {!selectedPuzzleNo && (
  <Tabs
    value={selectedTab}
    onChange={handleTabChange}
    indicatorColor="primary"
    textColor="primary"
    variant="fullWidth"
    sx={{
      '& .MuiTab-root': {
        minWidth: '150px', // Set the minimum width for tabs
        fontSize: '1rem',  // Adjust font size for better readability
        color: currentTheme.textColor,
      },
      '& .MuiTabs-indicator': {
        height: '4px',     // Adjust the height of the indicator
      },
    }}
  >
    <Tab label="Classic" />
    <Tab label="Double" />
  </Tabs>
)}

  {/* Puzzle Selection Interface or Puzzle Play */}
  {!selectedPuzzleNo ? (
    <PuzzleSelectionInterface
      stats={stats}
      puzzleList={puzzleList}
      setSelectedPuzzleNo={setSelectedPuzzleNo}
      puzzleType={puzzleTypeForHook} 
    />
  ) : (
    <PuzzleLayout
      archivePuzzleNo={selectedPuzzleNo}
      setSelectedPuzzleNo={setSelectedPuzzleNo}
      puzzleType={puzzleTypeForHook}
    />
  )}
</Box>

  );
};

export default ArchivePage;
