import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SidebarDrawer from './SidebarDrawer';
import CustomModal from './CustomModal';
import HeaderTitle from './HeaderTitle';
import { useThemeContext } from '../contexts/ThemeContext';
import Snowfall from 'react-snowfall'; 
import { isInIframe, addBlueHue, isWinterSeason, getReferrer } from '../utils/utilityFunctions';


const MainLayout = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const { currentTheme } = useThemeContext();
  const referrer = getReferrer().toLowerCase();
  const isNonThemeFrameSite = referrer.includes('coolmathgames') || referrer.includes('aarp');
  const shouldShowSnowfall = isWinterSeason() && isInIframe() && !isNonThemeFrameSite;
  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);
  

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
    setShowTooltip(false); 
  };

  const handleOpenHowToPlay = () => {
    setOpenModal('HowToPlay');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: shouldShowSnowfall
      ? addBlueHue(currentTheme.primaryColor)
      : currentTheme.primaryColor,
      }}
    >

      {/* Snowfall Background */}
      {shouldShowSnowfall && (
        <Snowfall
          style={{
            position: 'absolute', // Ensure it stays in the background
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0, 
          }}
          color='#fff'
          speed= {[0.25, 2.0]}
          wind= {[-0.25, 1.0]}
          snowflakeCount={50} // number of snowflakes
        />
      )}

      {/* Header */}
      <Box
        sx={{
          height: '40px',
          backgroundColor: currentTheme.secondaryColor,
          color: currentTheme.textColor,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* Left Icons */}
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
          <IconButton sx={{ color: currentTheme.textColor }} onClick={handleOpenDrawer}>
            <MenuIcon />
          </IconButton>

          {showTooltip && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '100%',
                transform: 'translateY(-50%) translateX(10px)',
                zIndex: 2000,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* Arrow */}
              <Box
                sx={{
                  width: 0,
                  height: 0,
                  borderTop: '10px solid transparent',
                  borderBottom: '10px solid transparent',
                  borderRight: '10px solid #3399FF',
                  animation: 'bounce 2s infinite',
                  '@keyframes bounce': {
                    '0%, 100%': { transform: 'translateX(0)' },
                    '50%': { transform: 'translateX(-10px)' },
                  },
                  
                }}
              />
              {/* Label */}
              <Box
                sx={{
                  backgroundColor: "#3399FF",
                  color: "#fff" ,
                  padding: '5px 10px',
                  borderRadius: '5px',
                  marginLeft: '5px',
                  whiteSpace: 'nowrap', 
                  animation: 'bounce 2s infinite',
                  '@keyframes bounce': {
                    '0%, 100%': { transform: 'translateX(0)' },
                    '50%': { transform: 'translateX(-10px)' },
                  },
                }}
              >
                More Puzzles!
              </Box>
            </Box>
          )}
        </Box>

        {/* Header Title */}
        <HeaderTitle />

        {/* Right Icons */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          <IconButton sx={{ color: currentTheme.textColor }} onClick={handleOpenHowToPlay}>
            <HelpOutlineIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Sidebar Drawer */}
      <SidebarDrawer
        isDrawerOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        handleOpenModal={setOpenModal}
      />

      {/* Modals */}
      {['Settings', 'HowToPlay', 'About'].map((modalType) => (
        <CustomModal
          key={modalType}
          open={openModal === modalType}
          onClose={() => setOpenModal(null)}
          modalType={modalType}
        />
      ))}

      {/* Main Content */}
      <Box
        sx={{
          marginTop: '50px',
          height: 'calc(100vh - 50px)',
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
