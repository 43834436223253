// src/components/ControlBox.js

import React from "react";
import { Box, Button, Divider } from "@mui/material";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useThemeContext } from "../contexts/ThemeContext";
import { useMediaContext } from "../contexts/MediaContext";
import { detectDeviceInputType } from '../utils/utilityFunctions';

// Base style for all control buttons
const buttonBaseStyle = (isMobile, isNarrowScreen) => ({
  color: "#ffffff",
  padding: "0.1em 0.3em",
  fontSize: isMobile ? "0.8em" : "0.9em",
  fontWeight: "bold",
  transition: "all 0.3s ease",
  margin: "0.05rem",
});

// Navigation button style
const buttonNavStyle = (currentTheme) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 1,
  background: currentTheme.secondaryColor,
  color: currentTheme.textColor,
  width: "100%",
  mb: 1,
  padding: "10px 15px",
  borderRadius: "10px",
  fontWeight: "bold",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.25)",
  },
});

const ControlBox = ({
  syllables,
  onShuffle,
  onSort,
  onClear,
  gameStarted,
  handleSyllableButtonClick,
  gameWon,
  puzzleType,
  handlePreviousPuzzle,
  handleNextPuzzle,
  isPrevDisabled,
  isNextDisabled,
  prevPuzzleNumber,
  nextPuzzleNumber,
  isPrevPuzzleCompleted,
  isNextPuzzleCompleted,
  archivePuzzleNo
}) => {
  const { currentTheme } = useThemeContext();
  const { isMobile, isNarrowScreen } = useMediaContext();
  const buttonWidth = isNarrowScreen ? "50px" : (isMobile ? "60px" : "90px"); // Button width based on mobile state
  const isTouchDevice = detectDeviceInputType() === "touch";
  const controlsDisabled = !gameStarted;

  const isTodaysDailyPuzzle = puzzleType === 'daily' && archivePuzzleNo === null
  const isTodaysDailyDoublePuzzle = puzzleType === 'daily-double' && archivePuzzleNo === null

  // Icon and text style for navigation buttons
  const iconStyle = {
    display: "flex",
    alignItems: "center",
    gap: "5px", // Space between icons and text
  };

  // Render a simplified layout if the game is won
  if (gameWon) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          border: `1px solid ${currentTheme.borderColor}`,
          backgroundColor: currentTheme.secondaryColor,
          width: isMobile ? "350px" : "300px",
          borderRadius: "8px",
          maxHeight: "90%",
          overflowY: "hidden",
          flex: 1,
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/logo512x512.png`}
          alt="Logo"
          style={{ height: 60, filter: currentTheme.logoInvertColor }}
        />

        {!(isTodaysDailyPuzzle || isTodaysDailyDoublePuzzle) && (
          <Box
            sx={{
              width: "90%",
              height: "2px",
              backgroundColor: currentTheme.textColor,
              marginBottom: "15px",
              marginTop: "15px",
            }}
          />
        )}

        {/* Previous and Next Buttons */}
        {!(isTodaysDailyPuzzle || isTodaysDailyDoublePuzzle) && (
          <Box
            sx={{ display: "flex", justifyContent: "space-between", width: "100%", mb: 2, gap: "5px" }}
          >
            {/* Previous Puzzle Button */}
            <Button
              onClick={handlePreviousPuzzle}
              disabled={isPrevDisabled}
              sx={{
                ...buttonNavStyle(currentTheme),
                border: `1px solid ${currentTheme.borderColor}`,
                flex: "1 1 45%",
                backgroundColor: isPrevDisabled ? "#d3d3d3" : "#2196f3",
                "&:hover": {
                  backgroundColor: isPrevDisabled ? "#d3d3d3" : "#1976d2",
                },
              }}
            >
              <Box sx={iconStyle}>
                <ArrowBackIcon />
                #{prevPuzzleNumber}
                {isPrevPuzzleCompleted && <CheckCircleIcon sx={{ color: "green" }} />}
              </Box>
            </Button>

            {/* Next Puzzle Button */}
            <Button
              onClick={handleNextPuzzle}
              disabled={isNextDisabled}
              sx={{
                ...buttonNavStyle(currentTheme),
                border: `1px solid ${currentTheme.borderColor}`,
                flex: "1 1 45%",
                backgroundColor: isNextDisabled ? "#d3d3d3" : "#2196f3",
                "&:hover": {
                  backgroundColor: isNextDisabled ? "#d3d3d3" : "#1976d2",
                },
              }}
            >
              <Box sx={iconStyle}>
                {isNextPuzzleCompleted && <CheckCircleIcon sx={{ color: "green" }} />}
                #{nextPuzzleNumber}
                <ArrowForwardIcon />
              </Box>
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  // Default ControlBox layout
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        border: `1px solid ${currentTheme.borderColor}`,
        backgroundColor: currentTheme.secondaryColor,
        width: isNarrowScreen ? "300px" : (isMobile ? "350px" : "300px"),
        borderRadius: "8px",
        maxHeight: isMobile || isNarrowScreen ? "42vh" : "90%",
        flex: 1,
        alignItems: "center",
      }}
    >
      {/* Syllable Buttons Container */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: isMobile ? "0.1rem" : "0.05rem",
          width: "100%",
          maxWidth: "100%",
          overflowY: "auto",
          paddingBottom: "3px",
          scrollbarWidth: "thin", // Use thin scrollbar for better UX
          scrollbarColor: `${currentTheme.primaryColor} transparent`, // Custom scrollbar colors
        }}
      >
        {syllables.map((syllable, index) => (
          <Button
            key={syllable.text + index}
            variant="contained"
            onClick={() => handleSyllableButtonClick(syllable.text)}
            disabled={!gameStarted || syllable.disabled}
            sx={{
              ...buttonBaseStyle(isMobile, isNarrowScreen),
              width: buttonWidth,
              backgroundColor: "#3399FF",
              border: `2px solid ${currentTheme.borderColor}`,
              "&:hover": {
                backgroundColor: isTouchDevice ? "none" : "#1c7bcc",
                transform: isTouchDevice ? "none" : "scale(1.05)",
                boxShadow: isTouchDevice ? "none" : "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
              "&.Mui-disabled": {
                backgroundColor: currentTheme.buttonDisabledBackground,
                color: currentTheme.buttonDisabled,
                border: `2px solid ${currentTheme.buttonDisabled}`,
              },
            }}
          >
            {syllable.text}
          </Button>
        ))}
      </Box>

      {/* Divider */}
      <Divider
        sx={{
          width: "100%",
          backgroundColor: currentTheme.borderColor,
          margin: "5px 0",
          height: "1px",
        }}
      />

      {/* Control Buttons (Shuffle, Sort, Clear) */}
      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        {[
          { label: "Shuffle", icon: <ShuffleIcon />, onClick: onShuffle, color: "#39b339", hoverColor: "#2e8c2e" },
          { label: "Sort", icon: <SortByAlphaIcon />, onClick: onSort, color: "#FFC107", hoverColor: "#e6a806" },
          { label: "Clear", icon: <ClearIcon />, onClick: onClear, color: "#ff3333", hoverColor: "#cc2929" },
        ].map(({ label, icon, onClick, color, hoverColor }, idx) => (
          <Button
            key={label}
            onClick={onClick}
            variant="contained"
            disabled={controlsDisabled}
            sx={{
              ...buttonBaseStyle(isMobile, isNarrowScreen),
              flex: "1 1 30%",
              backgroundColor: color,
              border: `2px solid ${currentTheme.borderColor}`,
              "&:hover": {
                backgroundColor: isMobile ? "none" : hoverColor,
                transform: isMobile ? "none" : "scale(1.05)",
                boxShadow: isMobile ? "none" : "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
              "&.Mui-disabled": {
                backgroundColor: currentTheme.buttonDisabledBackground,
                color: currentTheme.buttonDisabled,
                border: `2px solid ${currentTheme.buttonDisabled}`,
              },
            }}
          >
            {icon}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default ControlBox;
