import { useState, useEffect } from 'react';
import axios from 'axios';

// Custom hook to fetch today's puzzle numbers and dates
const useDailyPuzzleNumber = (shouldFetch) => {
  const [todayPuzzleNo, setTodayPuzzleNo] = useState(null);
  const [todayPuzzleDate, setTodayPuzzleDate] = useState(null);
  const [todayDailyDoublePuzzleNo, setTodayDailyDoublePuzzleNo] = useState(null);
  const [todayDailyDoublePuzzleDate, setTodayDailyDoublePuzzleDate] = useState(null);
  const [loading, setLoading] = useState(shouldFetch);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!shouldFetch) return;
  
    const fetchPuzzleNumbers = async () => {
      try {
        setLoading(true);
  
        const [dailyResponse, dailyDoubleResponse] = await Promise.all([
          axios.get('/api/puzzles/daily-puzzle'),
          axios.get('/api/puzzles/daily-double-puzzle'),
        ]);
  
        const dailyPuzzleData = dailyResponse.data;
        setTodayPuzzleNo(dailyPuzzleData.puzzle_no);
        setTodayPuzzleDate(dailyPuzzleData.puzzle_date);
  
        const dailyDoublePuzzleData = dailyDoubleResponse.data;
        setTodayDailyDoublePuzzleNo(dailyDoublePuzzleData.puzzle_no);
        setTodayDailyDoublePuzzleDate(dailyDoublePuzzleData.puzzle_date);
      } catch (err) {
        console.error("Failed to fetch today's puzzle numbers:", err);
        setError("Failed to fetch today's puzzle numbers.");
      } finally {
        setLoading(false);
      }
    };
  
    fetchPuzzleNumbers();
  }, [shouldFetch]);

  return {
    todayPuzzleNo,
    todayPuzzleDate,
    todayDailyDoublePuzzleNo,
    todayDailyDoublePuzzleDate,
    loading,
    error,
  };
};

export default useDailyPuzzleNumber;
