import React from 'react';
import PuzzleLayout from '../components/PuzzleLayout';

const DailyDoublePage = () => {

  return (
    <PuzzleLayout puzzleType="daily-double" />
  );
};

export default DailyDoublePage;