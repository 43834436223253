import React from 'react';
import { Box, Typography } from '@mui/material';
import { useThemeContext } from '../contexts/ThemeContext';
// import { isInIframe } from '../utils/utilityFunctions';

const HeaderTitle = () => {
  const { currentTheme } = useThemeContext();

  // Array representing the segments of the title
  const titleSegments = ['SYL', 'LA', 'CROS', 'TIC'];

  return (
    <Box
      sx={{
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{
          fontFamily: 'Arial, sans-serif',
          fontWeight: 'bold',
          fontSize: '1.2em',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* Dynamically render title segments */}
        {titleSegments.map((segment) => (
          <Box
            key={segment}
            sx={{
              borderBottom: `2px solid ${currentTheme.textColor}`,
              mr: 1,
              fontWeight: 'bold',
              lineHeight: 1,
              paddingBottom: '2px',
            }}
          >
            {segment}
          </Box>
        ))}

        {/* conditional trademark symbol */}
          {/* {!isInIframe() && (
            <Box
              component="span"
              sx={{
                fontSize: '0.7em',
                lineHeight: 1,
                position: 'relative',
                top: '-5px',
              }}
            >
              &reg;
            </Box>
          )} */}
      </Typography>
    </Box>
  );
};

export default HeaderTitle;
